<template>
  <div class="overflow-hidden w-screen transform -rotate-3 mt-20">
    <div class="flex -translate-x-10 h-80 w-full mb-5">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
    </div>
    <div class="flex h-80 w-full">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
      <img src="" class="mx-3 h-80 w-60 bg-white rounded-2xl">
    </div>
  </div>
</template>

<script>
export default {
  name: "HeroCardComponent"
}
</script>

<style scoped>

</style>