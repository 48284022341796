<template>
  <div class="bg-blue-900 h-screen">
    <SliderComponent></SliderComponent>
  </div>
</template>

<script>

import SliderComponent from "@/components/Front/SliderComponent";
export default {
  name: "Signin",
  components: {
    SliderComponent
  }
}
</script>

<style scoped>

</style>