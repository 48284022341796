<template>
  <div class="rounded-3xl overflow-hidden">
    <div class="h-72 flex relative rounded-t-3xl">
      <img class="w-full h-full object-cover" src="">
      <div class="absolute bottom-4 w-full flex justify-center">
        <button class="w-14 h-1 bg-yellow-500 mx-1"></button>
      </div>
    </div>
    <div class="bg-white rounded-b-3xl h-40 text-start">
      <h2 class="text-2xl font-bold">Lorem Ipsum Dolor Sit Amet</h2>
      <p></p>
    </div>
  </div>
</template>

<script>
export default {
  name: "CardSlideComponent"
}
</script>

<style scoped>

</style>